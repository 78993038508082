import SystemForm from '@/components/ManageDatabase/SystemForm/SystemForm.vue';
import ADD_SYSTEM_MUTATION from '@/graphql/mutations/addSystem.gql';
import SYSTEMS_QUERY from '@/graphql/queries/systems.gql';
import eventHub from '@/utils/eventHub';

export default {
  name: 'AddSystem',
  components: { SystemForm },
  data: () => ({
    ADD_SYSTEM_MUTATION,
    system: { name: '', category: null, finCode: null },
  }),
  methods: {
    updateSystems(store, { data: { createSystem } }) {
      if (createSystem) {
        // Read the data from our cache for this query.
        const data = store.readQuery({
          query: SYSTEMS_QUERY,
        });
        data.systems.unshift(createSystem);
        store.writeQuery({
          query: SYSTEMS_QUERY,
          data,
        });
        eventHub.$emit('show-snackbar', {
          color: 'success',
          text: `System ${this.system.name} was added successfully!`,
        });
      } else {
        eventHub.$emit('show-snackbar', {
          color: 'error',
          text: `Error! Something went wrong... Please try later!`,
        });
      }
    },
  },
};

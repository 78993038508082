var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApolloMutation',_vm._g({staticClass:"add-system-form",attrs:{"mutation":_vm.ADD_SYSTEM_MUTATION,"variables":{
    input: {
      name: _vm.system.name,
      categoryId: (_vm.system.category && _vm.system.category.id) || null,
      finCode: _vm.system.finCode,
    },
  },"update":_vm.updateSystems},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var mutate = ref.mutate;
  var loading = ref.loading;
  var error = ref.error;
return [(!loading)?_c('alert',{attrs:{"align":"center","error":error}}):_vm._e(),_c('system-form',_vm._b({on:{"submit":function($event){return mutate()}}},'system-form',{
        btnLabel: 'Create',
        loading: loading,
        system: _vm.system,
      },false))]}}])},_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }